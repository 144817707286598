<template>
  <div class="flex flex-column editor">
    <div class="editor__navbar">
      <div class="editor__navbar__left">
        <div>
          <el-popover
            placement="top-start"
            width="300"
            trigger="click">
            <div>
              <template v-for="item in Array.from(new Array(74).keys())">
                <el-image
                  style="cursor: pointer;"
                  @click="onFaceClick(`/images/chat/face/${item + 1}.gif`)"
                  :key="item"
                  :src="`/images/chat/face/${item + 1}.gif`"
                ></el-image>
              </template>
            </div>
            <el-image title="表情" slot="reference" src="/images/chat/icon_emoji.png"></el-image>
          </el-popover>
        </div>
        <div>
          <el-image title="发送图片" src="/images/chat/icon_photo.png" @click="onPickFile"></el-image>
          <input
            type="file"
            ref="fileInput"
            accept="image/*"
            @change="getFile"
            style="display: none"
          >
        </div>
        <div>
          <el-image title="发送文件" src="/images/chat/icon_upload.png"
                    @click="onPickUploadFile"></el-image>
          <input
            type="file"
            ref="uploadFileInput"
            @change="getUploadFile"
            style="display: none"
          >
        </div>
        <div title="声音" @click="switchPlaySound">
          <template v-if="isPlaySound">
            <el-image src="/images/chat/icon_ring.png"></el-image>
          </template>
          <template v-else>
            <el-image src="/images/chat/icon_ring_close.png"></el-image>
          </template>
        </div>
        <div>
          <el-popover
            placement="top-start"
            width="800px"
            trigger="click">
            <div>
              <div
                @click="complaintConfig.showDialog"
                class="flex flex-center"
                style="cursor: pointer;">
                <a style="color: white;
                background-color: #33D59A;
                width: 25px;
                height: 25px;
                border-radius: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;"
                ><i class="el-icon-document"></i></a>
                投诉客服
              </div>
            </div>
            <i slot="reference" class="el-icon-warning"></i>
          </el-popover>
        </div>
        <div>
          <i @click="identityConfig.showDialog" title="身份信息" class="el-icon-postcard fz-18"></i>
        </div>
      </div>
      <slot name="navbar"/>
    </div>
    <!--  聊天窗口域  -->
    <div
      @focus="onFocus"
      @blur="onBlur"
      ref="editor__content"
      class="editor__content flex-item1"
      @keydown="onKeyDown"
      :class="{'fc-tips' : !focus && !hasText(content)}"
      contenteditable="true"
      v-html="focus || hasText(content) ? content : placeholder"
    ></div>
    <div class="editor__footer">
      <div class="flex flex-sb pad-lr15 pad-tb5 bg-white">
        <div></div>
        <el-button size="mini"  @click="onSendClick" :style="{backgroundColor: btncolor,color:'white'}">发送</el-button>
      </div>
      <slot name="footer"/>
    </div>
    <!--  投诉客服 dialog  -->
    <el-dialog
      title="投诉客服"
      center
      :visible.sync="complaintConfig.show"
      width="300px">
      <el-form :model="complaintConfig.form"
               :rules="complaintConfig.formRules"
               ref="complaintForm"
      >
        <el-form-item prop="complainttype">
          <el-select v-model="complaintConfig.form.complainttype" placeholder="请选择">
            <el-option
              v-for="item in complaintConfig.condition.category"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="content">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入留言内容"
            v-model="complaintConfig.form.content">
          </el-input>
        </el-form-item>
        <div class="tc">
          <el-button @click="complaintConfig.onSubmitClick('complaintForm')" type="primary"
                     size="mini">提交
          </el-button>
        </div>
      </el-form>
    </el-dialog>
    <!--  输入用户身份 dialog  -->
    <el-dialog
      title="客户信息"
      center
      :visible.sync="identityConfig.show"
      width="300px">
      <el-form :model="identityConfig.form"
               :rules="identityConfig.formRules"
               ref="identityForm"
      >
        <el-form-item prop="area">
          <el-input v-model="identityConfig.form.nicename" placeholder="昵称"></el-input>
        </el-form-item>
        <el-form-item prop="area">
          <el-input v-model="identityConfig.form.address" placeholder="地区"></el-input>
        </el-form-item>
        <el-form-item >
          <el-radio-group v-model="identityConfig.form.gender">
            <el-radio label="M">男</el-radio>
            <el-radio label="F">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="age">
          <el-input v-model="identityConfig.form.age" placeholder="年龄"></el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input v-model="identityConfig.form.phone" placeholder="联系电话"></el-input>
        </el-form-item>
        <div class="tc">
          <el-button @click="identityConfig.onSubmitClick('identityForm')" type="primary"
                     size="mini">提交
          </el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import asyncValidator from "@/resources/js/async-validator";
  import {addOrUpdateUserInfo, addComplaint} from "@/apis/webstocket";
  import {getClientInfo } from "@/apis";
  import axios from 'axios'
  import config from "@/resources/js/config";

  export default {
    name: "ChatArea",
    props: {
      value: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      }, csid: {
        type: Number,
        default: 0
      }, projid: {
        type: Number,
        default: 0
      }, btncolor: {
        type: String,
        default: '#3153d4'
      }
    },
    data() {
      return {
        imgsVisible: false,
        appPath: window.location.origin,
        focus: false,
        fileName: null,
        size: 0,
        content: null,
        isPlaySound: true, // 是否允许播放声音 默认为true
        complaintConfig: {
          show: false,
          form: {
            complainttype: null, // 投诉类型
            content: null, // 输入理由
            projid: 0,
            csid: 0
          },
          formRules: {
            complainttype: [],
            content: [
              {required: true, message: '请输入留言内容', trigger: ['blur', 'change']},
            ]
          },
          showDialog: () => {
            this.complaintConfig.show = true;
          },
          condition: {
            category: [
              {value: "1", label: "态度问题"},
              {value: "2", label: "业务问题"}
            ],
          },
          onSubmitClick: (formName) => {
            let this_=this;
            this.$refs[formName].validate()
            .then(() => {
              this.complaintConfig.form.csid=this.csid;
              this.complaintConfig.form.projid=this.projid;
              addComplaint(this_.complaintConfig.form).then(res => {
                this.$message.success({message: "提交成功",offset:50});
              })
              this.$refs[formName].resetFields();
              this.complaintConfig.show = false;
            });
          },
        },
        identityConfig: {
          show: false,
          form: {
            gender: 'M',
            nicename: null,
            age: null,
            phone: null,
            address: null,
          },
          formRules: {
            nicename: [
              {required: true, message: '请输入昵称', trigger: ['change', 'blur']},
            ],
            address: [
              {required: true, message: '请输入地区', trigger: ['change', 'blur']},
            ],
            gender: [
              {required: true, message: '请选择性别', trigger: ['change', 'blur']},
            ],
            age: [
              {validator: asyncValidator.validAge, message: '年龄范围不正确', trigger: ['change', 'blur']},
            ],
            phone: [
              {
                validator: asyncValidator.validPhone,
                message: '手机号不正确',
                trigger: ['change', 'blur']
              },
            ]
          },
          showDialog: () => {
            this.identityConfig.show = true;
          },
          onSubmitClick: (formName) => {
            this.$refs[formName].validate()
            .then(() => {
              addOrUpdateUserInfo(this.identityConfig.form).then(res => {
                this.$message.success({message: "提交成功",offset:50});
              })
              this.identityConfig.show = false;
            });
          },
        },
      }
    },
    created() {
      this.content = this.value;
      this.getClientInfo();
    },
    watch: {
      value(val) {
        if (val !== this.content) {
          this.content = this.value;
        }
      },
    },
    methods: {
      getClientInfo(){
        getClientInfo().then(res => {
          if (res.retdata !=null){
            this.identityConfig.form=res.retdata;
          }
        })
      },
      switchPlaySound() {
        this.isPlaySound = !this.isPlaySound;
        this.$emit('toggleSound', this.isPlaySound);
      },
      onSendClick() {
        this.doSend();
      },
      onKeyDown(event) {
        if (event.keyCode === 13) {
          this.getContent(event);
          this.doSend();
          event.preventDefault() // 阻止浏览器默认换行操作
          return false
        }
      },
      doSend() {
        let {content} = this;
        if (this.hasText(content)) {
          console.log(content)
          this.$emit('send', content);
          this.resetEditArea();
        }
      },
      resetEditArea() {
        this.content = null;
        this.$refs['editor__content'].innerHTML = '';
      },
      onFocus() {
        this.focus = true;
      },
      onBlur(event) {
        this.focus = false;
        this.getContent(event);
      },
      getContent(event) {
        this.content = event.target.innerHTML;
      },
      onFaceClick(src) {
        let div = document.createElement('div');
        let html = '<img src="' + src + '" onclick="imgView(\'' + src
          + '\')"  style="margin: 10px;max-width: 100px;object-fit: cover;"></img>'
        div.innerHTML = html
        this.appendContent(div);
      },
      onUploadFileClick(src) {
        let div = document.createElement('div');
        div.innerHTML = '<div style="height: 70px;margin: 10px;width: 200px;background-color: white;box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);font-size: 12px;line-height: 20px">'
          + '<div>'
          + '<div style="float: right;line-height: 70px;padding-right: 20px">' + this.size
          + '</div>'
          + '<div style="float: left;padding: 10px 2px;text-align: left">'
          + ' <div>' + this.getFileName(this.fileName) + '</div>'
          + ' <div style="padding: 10px 5px"><a href="' + (src)
          + '" target="_blank" style="height: 100%;color: #09f3df">点击查看</a></div>'
          + '</div>'
          + '</div>'
          + '</div>';
        this.appendContent(div);
      },
      appendContent(content) {
        let appendString = '';
        if (content instanceof Element) {
          let temp = document.createElement('div');
          temp.appendChild(content);
          appendString = temp.innerHTML;
        }
        if (this.hasText(this.content)) {
          this.content = this.content + appendString;
        } else {
          this.content = appendString;
        }
      }, onPickFile() {
        this.$refs.fileInput.click()
      }, onPickUploadFile() {
        this.$refs.uploadFileInput.click()
      },
      getFile(event) {
        const files = event.target.files
        let filename = files[0].name          //只有一个文件
        if (filename.lastIndexOf('.') <= 0) {
          this.$message.error("请上传有效图片")        //判断图片是否有效
          return;
        }
        if (files[0].size >10485760) {
          this.$message.error("图片不得大于10M")        //判断图片是否有效
          return;
        }
        const fileReader = new FileReader()                //内置方法new FileReader()   读取文件
        fileReader.addEventListener('load', () => {
          this.imageUrl = fileReader.result
        })
        fileReader.readAsDataURL(files[0])
        this.image = files[0]
        //到这里后, 选择图片就可以显示出来了
        this.onUpload(event, 1);
      }, getUploadFile(event) {
        const files = event.target.files
        let filename = files[0].name          //只有一个文件
        if (filename.lastIndexOf('.') <= 0) {
          this.$message.error("请上传有效文件")
          return;
        }
        if (files[0].size >10485760 ) {
          this.$message.error("文件不得大于10M")        //判断图片是否有效
          return;
        }
        const fileReader = new FileReader()
        fileReader.readAsDataURL(files[0])
        this.image = files[0]
        this.fileName = this.image.name
        this.size = this.getFileSize(this.image.size)
        this.onUpload(event, 2);
      },
      //第四步: 上传文件了
      onUpload(event, type) {
        event.preventDefault();
        let fd = new FormData()              //内置方法new FormData()  新建一个表格
        fd.append('file', this.image)          //把image添加进去
        // axios.post(`${window.location.origin}${config.BASE_URL}/uploads/uploadFile`,fd
        axios.post(this.appPath + `${config.BASE_URL}/uploads/uploadFile`, fd
        ).then(res => {                 //第一个参:this.postUrl就是上面保存好的要上传的地址
          if (type == 1) {
            this.onFaceClick(res.retdata.preview);
          } else {
            this.onUploadFileClick(res.retdata.preview);
          }
        }).catch(error => {
        })
      }, getFileSize(fileByte) {
        var fileSizeByte = fileByte;
        var fileSizeMsg = "";
        if (fileSizeByte < 1048576) {
          fileSizeMsg = (fileSizeByte / 1024).toFixed(2) + "KB";
        } else if (fileSizeByte == 1048576) {
          fileSizeMsg = "1MB";
        } else if (fileSizeByte > 1048576 && fileSizeByte < 1073741824) {
          fileSizeMsg = (fileSizeByte
            / (1024 * 1024)).toFixed(2) + "MB";
        } else if (fileSizeByte > 1048576 && fileSizeByte == 1073741824) {
          fileSizeMsg = "1GB";
        } else if (fileSizeByte > 1073741824 && fileSizeByte
          < 1099511627776) {
          fileSizeMsg = (fileSizeByte / (1024 * 1024 * 1024)).toFixed(2) + "GB";
        } else {
          fileSizeMsg = "文件超过1TB";
        }
        return fileSizeMsg;
      }, getFileName(filename) {
        if (filename.length > 18) {
          filename = filename.substring(0, 8) + "..." + filename.substring(filename.length - 7,
            filename.length)
          return filename;
        } else {
          return filename;
        }
      }
    },
    computed: {
      hasText() {
        return function (str) {
          return str && str.trim().length > 0;
        }
      }
    },
  }
</script>

<style scoped lang="scss">
  .editor__navbar {
    padding: 5px 10px;

    .editor__navbar__left {
      display: flex;

      & > div {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }

      .el-image, i {
        font-size: 12px;
        position: relative;
        white-space: nowrap;
        margin: 2px 6px;
        cursor: pointer;
      }

      i {
        font-size: 17px;
        color: #767676;
      }
    }
  }

  .editor ::v-deep {
    height: 170px;
    background-color: white;
    border-top: 1px solid #e8e0e0;

    .editor__content {
      padding: 0 10px;
      height: 100%;
      width: 100%;
      border: none;
      outline: 0;
      font-family: Micrsofot Yahei, serif;
      font-size: 14px;
      overflow-y: auto;

      &:focus {
        outline: none;
      }
    }
  }

</style>
<style>
  .el-form-item {
    margin-bottom: 20px !important;
  }
  .el-dialog__body {
    padding: 10px 20px !important;
  }
  .el-form-item__content {
    margin-left: 0px !important;
  }
  .el-dialog__header {
    padding: 15px 20px 10px !important;
  }
  .el-message--success {
    top: 50px !important;
    left: 50% !important ;
  }
  .el-message {
    min-width: 250px !important;
  }
</style>
